@import "../../../../styles/_fonts";
@import "../../../../styles/_colors";

.accordion-item {
  align-items: center;
  cursor: pointer;
  width: 100%;
  text-align: center;
  text-transform: uppercase;
  svg {
    transform: rotate(90deg);
    width: 30px;
    position: relative;
    top: 9px;
    left: 6px;
    path {
      fill: $teal;
    }
  }
  &.active {
    svg {
      transform: rotate(-90deg);
    }
  }
}

.accordion:hover {
  // background-color: #ccc;
  opacity: 0.8;
}

.panel {
  padding: 0 18px;
  background-color: white;
  overflow: hidden;
  max-height: 0;
  transition: max-height 1s ease-in-out;

  &.open {
    transition: max-height 1s ease-in-out;
    max-height: 5000px;
  }
}
