$blue-gray: #5d8185;
$teal: #009a89;
$pale-turquoise: #a8ffcc;
$reddish-orange: #fc4a1a;
$fill-gray: #989898;
$fill-red: #ff7171;
$fill-black: #3d3d3d;
$gunmetal: #3e575a;
$light-black: #223031;
$greyish-brown: #474747;
$black: #000000;
$white: #ffffff;
