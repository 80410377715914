@import "../../../styles/fonts";
@import "../../../styles/variables";
@import "../../../styles/colors";

.cookie-banner {
  position: fixed;
  width: 100%;
  bottom: 0;
  left: 0;
  background-color: $pale-turquoise;
  padding: 20px;

  .wrapper {
    display: flex;
    flex-direction: column;

    @media (min-width: $medium-width) {
      flex-direction: row;
      justify-content: space-between;
    }
    width: 1440px;
    max-width: 100%;
    margin: 0 auto;
  }

  .actions {
    min-width: 230px;
    .button {
      margin-top: 24px;
      &:last-of-type {
        margin-left: 10px;
      }
      @media (min-width: $medium-width) {
        margin-top: 0;
      }
    }
  }
}
