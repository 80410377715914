@import "./reset";
@import "./fonts";
@import "./variables";

body {
  margin: 0;
  font-family: $primary-font;
  font-size: 16px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  line-height: 1.5;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

* {
  box-sizing: border-box;
}

.hide-xs-down {
  @media (max-width: $small-width) {
    display: none !important;
  }
}

.hide-md-down {
  @media (max-width: $medium-width) {
    display: none !important;
  }
}

.hide-lg-down {
  @media (max-width: $large-width) {
    display: none !important;
  }
}

.hide-xl-down {
  @media (max-width: $max-width) {
    display: none !important;
  }
}

.hide-xs-up {
  @media (min-width: $small-width) {
    display: none !important;
  }
}

.hide-md-up {
  @media (min-width: $medium-width) {
    display: none !important;
  }
}

.hide-lg-up {
  @media (min-width: $large-width) {
    display: none !important;
  }
}

.hide-xl-up {
  @media (min-width: $max-width) {
    display: none !important;
  }
}
