@import "./styles/variables";

.app-wrapper {
  width: $max-width;
  max-width: 100%;
  margin: 0 auto;
  min-height: calc(100vh - 139px);
}
main.main-content {
  @media (max-width: 1000px) {
    padding: 0 10px;
  }
}
.main-content {
  width: 100%;
  min-height: calc(100vh - 139px);
  max-width: $content-width;
  margin: 30px auto 24px;
  @media (max-width: $medium-width) {
    padding-left: 20px;
    padding-right: 20px;
  }

  &.not-found {
    padding-top: 150px;
    min-height: calc(100vh - 139px);
    margin-top: 0;
  }
}

.hide-small {
  @media (max-width: 640px) {
    display: none !important;
  }
}
